export default function distance(from, to, unit) {
    const lat1 = from.latitude;
    const long1 = from.longitude;
    const lat2 = to.latitude;
    const long2 = to.longitude;

    let distance;

    if ((lat1 === lat2) && (long1 === long2)) {
        distance = 0;
    }
    else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = long1-long2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit==="K") { dist = dist * 1.609344 }
        if (unit==="N") { dist = dist * 0.8684 }
        distance = dist;
    }

    return distance;
}
