import Placeholder from "../components/Placeholder";
import Menu from "../components/Menu";

export default class KFCRouter {
    constructor (reactRouter) {
        this.reactRouter = reactRouter;

        this.routes = {
            home: {
                path: '/',
                component: Placeholder,
                exact: true,
                strict: true
            },
            menu: {
                path: '/menu',
                component: Menu,
                exact: true,
                strict: true
            },
            deals: {
                path: '/deals',
                component: Placeholder,
                exact: true,
                strict: true
            },
            promos: {
                path: '/promos-rewards',
                component: Placeholder,
                exact: true,
                strict: true
            },
            catering: {
                path: '/catering',
                component: Placeholder,
                exact: true,
                strict: true
            },
            login: {
                path: '/login',
                component: Placeholder,
                exact: true,
                strict: true
            }
        }
    }

    getEntries() {
        return Object.keys(this.routes).map(key => this.getEntry(key));
    }

    getPath(route) {
        return route.path;
    }

    getEntry(keyName) {
        const entry = { ...this.routes[keyName]};
        return entry;
    }

}