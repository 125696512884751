import React, { useCallback, useEffect, useRef, useState } from "react";
import API_KEY from '../constants/GoogleApiKey';
import RedMarker from '../images/RedMarker.svg';
import distance from "../utils/distance";
import throttle from '../utils/throttle'
import { getKFCLocations, getKFCLocationsByBounds } from "../api/getKFCLocations";
import Thanks from './Thanks';

const Map = ({ latitude, longitude, locations }) => {
    const mapRef = useRef(null);
    let map = useRef(null);
    const [ mapJSCalled, setMapJSCalled ] = useState(false);
    const [ nearbyLocations, setNearbyLocations ] = useState(null);
    const [ locationsOutput, setLocationsOutput ] = useState([]);
    const [ thanks, setThanks ] = useState(true);
    const [ mapCenterLatitude, setMapCenterLatitude] = useState(null);
    const [ mapCenterLongitude, setMapCenterLongitude] = useState(null);
    const [ mapLoaded, setMapLoaded ] = useState(false);
    const [ markers, setMarkers ] = useState([]);
    const [ openedInfoWindow, setOpenedInfoWindow ] = useState(null);
    const [ tilesLoaded, setTilesLoaded ] = useState(false);

    const outputLocations = (nearbyLocations) => {
        const output = nearbyLocations.map((location, index) => {
            return (
                <li key={index}>
                    <div className='address'>
                        <p>{location.address}</p>
                        <p>{location.distance}</p>
                        <p>Hours: 10am – 11pm</p>
                    </div>
                    <button className='kfc-button red' onClick={toggleThanks}>Order Now</button>
                </li>
            );
        });
        return output;
    };

    const toggleThanks = useCallback(() => {
        setThanks(!thanks);
        openedInfoWindow.close();
    }, [thanks, openedInfoWindow]);

    useEffect(() => {
        const getNearbyLocations = () => {
            const kfcs = locations.locations;
            const retrievedNearbyLocations = getKFCLocations({kfcs, latitude, longitude});

            setNearbyLocations(retrievedNearbyLocations);
        }

        getNearbyLocations();
    }, [latitude, longitude, locations]);

    const loadMap = useCallback(() => {
        const coords = new window.google.maps.LatLng(latitude,longitude);
        const loadedMap = new window.google.maps.Map(mapRef.current, {
            center: coords,
            zoom: 9
        });

        return loadedMap;
    }, [latitude, longitude]);

    const reloadLocations = useCallback(() => {
        if (!map.current) return;
        const latlngbounds = map.current.getBounds();
        const southWest = latlngbounds.getSouthWest();
        const northEast = latlngbounds.getNorthEast();

        let insideBounds = true;

        if (latitude < southWest.lat() || latitude > northEast.lat()) {
            // latitude is outside of the map bounds
            insideBounds = false;
        }
        if (longitude < southWest.lng() || longitude > northEast.lng()) {
            // longitude is outside of the map bounds
            insideBounds = false;
        }

        const newCenter = map.current.getCenter();
        const distanceLatitude = insideBounds? latitude : newCenter.lat();
        const distanceLongitude = insideBounds? longitude : newCenter.lng();
        const distanceObject = {
            latitude: distanceLatitude,
            longitude: distanceLongitude
        };

        const kfcs = locations.locations;
        const retrievedNearbyLocations = getKFCLocationsByBounds({kfcs, latitude, longitude, distanceObject, latlngbounds});

        markers.map(marker => marker.setMap(null));
        setMarkers([]);

        setNearbyLocations(retrievedNearbyLocations);
        setMapCenterLatitude(latitude);
        setMapCenterLongitude(longitude);
    }, [locations, latitude, longitude, markers]);

    useEffect(() => {
        if (tilesLoaded) {
            map.current.addListener('bounds_changed', throttle(reloadLocations, 200));
            setTimeout(reloadLocations, 50);
        }
        // TODO: Figure out why this reloads constantly if all the dependencies are included
        //eslint-disable-next-line
    }, [tilesLoaded]);

    const initMap = useCallback(async() => {
        if (nearbyLocations && nearbyLocations.length) {

            if (map.current === null) {
                map.current = loadMap();
            }

            const markerIcon = {
                url: RedMarker,
                scaledSize: new window.google.maps.Size(30, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 15)
            };

            const infoWindow = new window.google.maps.InfoWindow({});

            const latlngbounds = new window.google.maps.LatLngBounds();

            for (let i = 0; i < nearbyLocations.length; i++) {
                const place = nearbyLocations[i];
                const button = document.createElement('button');
                button.setAttribute('class', 'kfc-button red');
                button.onclick = toggleThanks;
                button.innerText = 'Order Now';
                const address = document.createElement('p');
                address.innerText = place.address;
                let content = document.createElement('div');
                content.appendChild(address);
                content.appendChild(button);

                const position = new window.google.maps.LatLng(place.latitude,place.longitude);
                latlngbounds.extend(position);
                const marker = new window.google.maps.Marker({
                    position,
                    map: map.current,
                    title: place.address,
                    icon: markerIcon
                });
                marker.addListener('click', () => {
                    openedInfoWindow?.close();
                    infoWindow.setContent(content);
                    infoWindow.open({
                        anchor: marker,
                        map: map.current
                    });
                    setOpenedInfoWindow(infoWindow);
                });
                markers.push(marker);
            }

            if (!mapLoaded) {
                map.current.fitBounds(latlngbounds);
                setMapLoaded(true);

                // prevent map from zooming in too far if there's only one location
                const listener = window.google.maps.event.addListener(map.current, "idle", function() {
                    if (map.current.getZoom() > 13) map.current.setZoom(13);
                    window.google.maps.event.removeListener(listener);
                });

                const listener2 = window.google.maps.event.addListener(map.current, "idle", function() {
                    if (map.current.getZoom() < 8) map.current.setZoom(8);
                    window.google.maps.event.removeListener(listener2);
                });

                map.current.addListener('dragend', reloadLocations);

                map.current.addListener('tilesloaded', () => {
                    setTilesLoaded(true);
                    map.current.addListener('idle', reloadLocations);
                });
            }

        }
    }, [nearbyLocations, toggleThanks, loadMap, mapLoaded, markers, openedInfoWindow, reloadLocations]);

    useEffect(() => {
        const test = document.getElementById('placesApi');
        if (!test) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=noop`;
            script.id = 'placesApi';
            script.onload = initMap;
            document.head.appendChild(script);
            setMapJSCalled(true);
        }
    }, [mapJSCalled, initMap]);

    const initListings = useCallback(({latitude, longitude, nearbyLocations, initMap}) => {
        if (nearbyLocations && nearbyLocations.length) {
            const locationsMassaged = nearbyLocations.map((location) => {
                const storeCoords = {
                    latitude: location.latitude,
                    longitude: location.longitude
                };

                const centerLatitude = (mapCenterLatitude ? mapCenterLatitude : latitude).toFixed(5);
                const centerLongitude = (mapCenterLongitude ? mapCenterLongitude : longitude).toFixed(5);

                // const unitString = location.formatted_address.indexOf('United States') > 0 ? 'M' : 'K';
                // const distanceMeasure = location.formatted_address.indexOf('United States') > 0 ? 'miles away' : 'kilometers away';

                const unitString = 'M';
                const distanceMeasure = 'miles away';
                const addressString = `${location.address}, ${location.city}, ${location.state} ${location.zip}`;
                const distanceObject = {
                    latitude: centerLatitude,
                    longitude: centerLongitude
                };

                const distanceToStore = distance(distanceObject, storeCoords, unitString).toFixed(2);
                const distanceString = `${distanceToStore} ${distanceMeasure}`;
                return {
                    address: addressString,
                    distance: distanceString
                }
            });

            setLocationsOutput(locationsMassaged);

            initMap();
        }
    }, [mapCenterLatitude, mapCenterLongitude]);

    useEffect(() => {
        initListings({latitude, longitude, nearbyLocations, initMap});
    }, [latitude, longitude, nearbyLocations, initMap, initListings]);

    return (
        <section className='location-display'>
            <div ref={mapRef} id="map" />
            { locationsOutput.length &&
                <ul id="list">
                    { outputLocations(locationsOutput) }
                </ul>
            }
            <Thanks
                thanks={thanks}
                toggleThanks={toggleThanks}
            />
        </section>
    );
};

export default Map;
