import React, { useEffect, useState } from "react";
import Map from './Map';
import { getGeolocatedLocation, getIPAddress, getIPLocation } from "../api/locationApi";


function Find(locations) {
    const [location, setLocation] = useState({});
    const [geolocationFailed, setGeolocationFailed] = useState(false);
    const [ipAddress, setIpAddress] = useState({});

    useEffect(() => {
        async function fetchData() {
            try {
                const locationData = await getGeolocatedLocation();
                if (locationData.longitude && locationData.latitude) {
                    setLocation(locationData)
                }
            } catch(e) {
                console.error(e);
                setGeolocationFailed(true);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchAddress() {
            if (geolocationFailed) {
                const ip = await getIPAddress();
                if (typeof ip === 'string') {
                    setIpAddress(ip);
                }
            }
        }
        fetchAddress();
    }, [geolocationFailed]);

    useEffect(() => {
        async function fetchData() {
            if (typeof ipAddress === 'string') {
                const IPLocation = await getIPLocation(ipAddress);
                const [latitude, longitude] = IPLocation.split(',');
                const locationObj = {
                    latitude : parseFloat(latitude),
                    longitude : parseFloat(longitude)
                }
                setLocation(locationObj);
            }
        }
        fetchData();
    }, [ipAddress]);

    return (
        <div className="kfc-map">
            {location.latitude && location.longitude && locations ? (
                <Map latitude={location.latitude} longitude={location.longitude} locations={locations} />
            ) : (
                <div className='loading'>
                    <p>Loading...</p>
                </div>
            )}
        </div>
    );
}

export default Find;
