import React from "react";
const Splash = (props) => {
    return (
        <dialog id="splash" aria-hidden={props.hideSplash}>
            <h3>Welcome!</h3>
            <p>You have landed on the KFC Location Feature Prototype to test an upfront and shortened customer location flow. We are also testing some technical concepts including a tiered restaurant location data structure. Thanks.</p>
            <button className='kfc-button red' onClick={props.closeSplash}>OK</button>
        </dialog>
    )
}

export default Splash;
