import React, {Component} from 'react';
import headerConfig from '../data/header-config.au.json';

import Placeholder from '../components/Placeholder';
import Menu from '../components/Menu';
import Find from '../components/Find';
import Thanks from '../components/Thanks';
import Splash from '../components/Splash';

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from 'react-router-dom';

import Header from '../components/Header';
import KFCRouter from '../routes/KFCRouter';
import closeIcon from "../images/PopupCloseBtn.svg";
import { loadKFCLocations } from "../api/getKFCLocations";
import API_KEY from "../constants/GoogleApiKey";

class KFC extends Component {

    constructor(props, context) {
        super(props, context);

        this.reactRouter = null;
        this.router = null;
        this.config = headerConfig;

        this.locationRef = React.createRef();

        this.state = {
            locations: [],
            locationHidden: false,
            routes: null,
            showMap: false,
            splashHidden: false,
            thanks: true
        }
    }

    getLocation = () => {
        this.setState({
            locationHidden: false
        });
    };

    closeLocationPrompt = () => {
        this.setState({
            locationHidden: true,
            showMap: false
        });
    }

    closeSplash = () => {
        this.setState({
            splashHidden: true
        });
    }

    checkLocation = () => {
        this.setState({
            showMap: true
        });
    }

    handleGetLocation = () => {
        this.getLocation();
    }

    componentDidMount() {
        this.router = new KFCRouter(this.reactRouter);

        this.getLocations();

        const test = document.getElementById('placesApi');
        if (!test) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=noop`;
            script.id = 'placesApi';
            // script.onload = initMap;
            document.head.appendChild(script);
        }

        this.setState({
            routes: this.router.getEntries()
        });
    }

    async getLocations() {
        try {
            const newLocations = await loadKFCLocations();
            this.setState({
                locations: newLocations
            });
        } catch(e) {
            console.error(e);
        }
    }

    toggleThanks = () => {
        this.setState({
            thanks: !this.state.thanks,
            locationHidden: true
        });
    }

    renderRoute(route, index) {
        return (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={props => (
                    <route.component {...props} />
                )}
            />
        );
    }

    render() {
        return (
            <Router basename="/">
                <Header
                    config = { this.config }
                    router = { Router }
                    showMap = { this.state.showMap }
                    locationHidden = { this.state.locationHidden }
                />
                <main>
                    {
                        this.state.routes && 
                        <Routes>
                            <Route path="/home" element={<Placeholder getLocation={this.handleGetLocation} />} />
                            <Route path="/menu" element={<Menu />} />
                            <Route path="/deals" element={<Placeholder getLocation={this.handleGetLocation} />} />
                            <Route path="/promos" element={<Placeholder getLocation={this.handleGetLocation} />} />
                            <Route path="/catering" element={<Placeholder getLocation={this.handleGetLocation} />} />
                            <Route path="/signin" element={<Placeholder getLocation={this.handleGetLocation} />} />
                            <Route path="/cart" element={<Placeholder getLocation={this.handleGetLocation} />} />
                            <Route path="/find" element={<Find />} />
                            <Route path="/faq" element={<Placeholder getLocation={this.handleGetLocation} />} />
                            <Route path="/contact" element={<Placeholder getLocation={this.handleGetLocation} />} />
                            <Route path="/" element={<Navigate to="/home" />} />
                        </Routes>
                    }
                </main>
                { !this.state.showMap &&
                    <dialog className='location-prompt' aria-hidden={this.state.locationHidden} ref={this.locationRef}>
                        <button className='modal-close' onClick={this.closeLocationPrompt}><img src={closeIcon} alt='close' /></button>
                            <div className='ready-to-order'>
                                <h3>Ready to Order?</h3>
                                <div className='location-ctas'>
                                    <div className="location-ctas__row">
                                        <button className='kfc-button red' onClick={this.checkLocation}>Pickup</button>
                                        <button className='kfc-button red' onClick={this.checkLocation}>Delivery</button>
                                    </div>
                                    <div className="location-ctas__row">
                                        <button className='kfc-button' onClick={this.toggleThanks}>Maybe Later</button>
                                    </div>
                                </div>
                            </div>
                    </dialog>
                }
                { this.state.showMap &&
                    <dialog className='location-prompt location-prompt__wide' aria-hidden={this.state.locationHidden} ref={this.locationRef}>
                        <button className='modal-close' onClick={this.closeLocationPrompt}><img src={closeIcon} alt='close' /></button>
                        <Find locations={this.state.locations} toggleThanks={this.toggleThanks} />
                    </dialog>
                }
                <Thanks
                    thanks={this.state.thanks}
                    toggleThanks={this.toggleThanks}
                    />

                <Splash
                    hideSplash={this.state.splashHidden}
                    closeSplash={this.closeSplash}
                    />
            </Router>
        )
    };
}

export default KFC;