import heroImage from '../images/screen1-hero.jpg';
import mobileHero from '../images/mobile-web.jpg';
import menuImage from '../images/screen1-menu.jpg';

/**
 * @class Placeholder
 * @description Temporary component to fill space by not-yet-developed components.
 * @returns {JSX}
 */
function Placeholder(props) {
    const myGetLocation = () => {
        props.getLocation();
    };

    return (
        <main className="kfc-placeholder">
            <button onClick={() => {myGetLocation()}}>
                <img src={heroImage} className="desktop" alt="Order Now" />
                <img src={mobileHero} className="mobile" alt="Order Now" />
            </button>

            <img src={menuImage} className="desktop" alt="menu" />
        </main>
    );
}

export default Placeholder;
