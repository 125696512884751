import React from "react";
const Thanks = (props) => {
    return (
        <div className="order-thanks__container" aria-hidden={props.thanks}>
            <div className='modal-undercoat'></div>
            <dialog id="order-thanks">
                <h3>Thanks!</h3>
                <p>Once location has been determined, we can proceed to the location-specific order pages.</p>
                <button className='kfc-button red' onClick={props.toggleThanks}>OK</button>
            </dialog>
        </div>
    )
}

export default Thanks;
