export default function throttle(cb, delay) {
    let wait = false;

    return (...args) => {
        if (wait) {
            return;
        }

        cb(...args);
        wait = true;
        setTimeout(() => {
            wait = false;
        }, delay);
    }
}
