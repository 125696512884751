export function getGeolocatedLocation () {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
            reject(new Error("Geolocation is not supported by this browser."));
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    reject(error);
                }
            );
        }
    });
}

export function getIPAddress() {
    const url = 'https://api.ipify.org?format=json';
    return fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => data.ip)
        .catch(error => {
            console.error('Error fetching IP address:', error);
            return null;
        });
}

export function getIPLocation(ipAddress) {
    console.log(ipAddress);
    const url = `https://ipinfo.io/${ipAddress}?token=b122de986e5074`;
    return fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => data.loc)
        .catch(error => {
            console.error('Error fetching IP location:', error);
            return null;
        });
}

// eslint-disable-next-line
export default {
    getGeolocatedLocation,
    getIPAddress,
    getIPLocation
}