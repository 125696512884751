import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/kfcLogo.svg';
import accountIcon from '../images/Account_Icon.svg';
import locationIcon from '../images/Find_KFC.svg';
import menuIcon from '../images/MenuIcon.svg';
import closeIcon from "../images/PopupCloseBtn.svg";

/**
 * @class Header
 * @description Creates the header component
 * @param {Object} config 
 * @returns {JSX}
 */
const Header = (config) => {
    const mobileRef = useRef(null);
    const [ mobileHidden, setMobileHidden ] = useState(true);

    const constructLink = (link) => {
        let linkContent;
        let action = 'navigate';

        if (link.onClick) {
            action = link.onClick;
        }

        switch(link.type) {
            case "signin":
            case "find":
                const linkClass = `menu-link ${link.type}-link`;
                const linkIcon = link.type === 'signin' ? accountIcon : locationIcon;
                linkContent = <li key={link.key} className={linkClass}><Link to={link.key} onClick={action}><img src={linkIcon} alt={link.image.alt} /><span className="signin-copy">{link.copy}</span></Link></li>;
                break;
            case "cart":
                if(link?.currency) {
                    linkContent = <li key={link.key} className="menu-link cart-link"><Link to={link.key} onClick={action}><span className="header-price">{link.currency}0</span><div className="cart-icon-count">0</div></Link></li>
                } else {
                    linkContent = <li key={link.key} className="menu-link cart-link"><Link to={link.key} onClick={action}><div className="cart-icon-count">0</div></Link></li>
                }
                break;
            default:
                linkContent = <li key={link.key} className="menu-link"><Link to={link.key} onClick={action}>{link.copy}</Link></li>
                break;
        };

        return linkContent;
    };

    const constructMenu = (config) => {
        const reConfig = config?.config || {};
        const menu = reConfig?.menuLinks.map((link) => {
            return (
                constructLink(link)
            );
        });

        return (
            menu
        );
    };

    const constructMobile = (config) => {
        const reConfig = config?.config || {};
        const menu = reConfig?.mobileLinks.map((link) => {
            link.onClick = toggleMenu;
            return (
                constructLink(link)
            )
        });

        return (
            menu
        );
    }

    const constructMeta = (config) => {
        const reConfig = config?.config || {};
        const menu = reConfig?.metaLinks.map((link) => {
            return (
                constructLink(link)
            )
        });
        return (
            menu
        );
    };

    const toggleMenu = () => {
        setMobileHidden(!mobileHidden);
    }

    return (
        <header className="header">
            <nav className="header-nav">
                <div className="mobile-nav" ref={mobileRef} aria-hidden={mobileHidden}>
                    <button className="mobile-nav-chickenburger" onClick={toggleMenu}>
                        <img src={menuIcon} className="menu-icon" alt="Site Menu" />
                        <img src={closeIcon} className="close-icon" alt="Close Site Menu" />
                    </button>
                    <ul className="mobile-menu">
                        { constructMenu(config) }
                        { constructMobile(config) }
                    </ul>
                </div>
                <div className="header-left">
                    <div className="header-logo">
                        <Link to="home"><img src={logo} className="logo" alt="KFC®" /></Link>
                    </div>
                    <ul className="menu-links-container">
                        { constructMenu(config) }
                    </ul>
                </div>
                <ul className="meta-links-container">
                    { constructMeta(config) }
                </ul>
            </nav>
        </header>
    );
}

export default Header;
