import KDBush from 'kdbush';

// import distance from "../utils/distance";
import kfcsRaw from "../data/kfc-us.json";

//let kfcs = JSON.parse(appStorage.getString('kfcs') || '[]');
//let hasKFCsData = false;

let geospatialIndex = null;

export const loadKFCLocations = () => {
    //fetch('https://renegade.plan-net.dev/data/kfc-us.json').then(res => res.json()).then(json => {  // return to this once CORS is solved }
        const json = kfcsRaw;
        const kfcs = json.map(arr => ({
            id: arr[0],
            latitude: arr[1],
            longitude: arr[2],
            address: arr[3],
            city: arr[4],
            state: arr[5],
            zip: arr[6],
        }));
        geospatialIndex = new KDBush(
            json.map(arr => ([arr[1], arr[2]])),
            // "getX()" .. aka getLatitude
            p => p[0],
            // "getY()" .. aka getLongitude
            p => p[1],
            // Size of the KD-tree node, 64 by default. Higher means faster indexing but slower search, and vise versa.
            8
        );
        return kfcs;
}

export const getDistances = (kfcs, latitude, longitude) => {
    const distances = kfcs.map(location => {
        const earthRadius = 6371; // Earth's radius in km
        const dLat = (location.latitude - latitude) * Math.PI / 180;
        const dLon = (location.longitude - longitude) * Math.PI / 180;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(latitude * Math.PI / 180) * Math.cos(location.latitude * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c;
        return {location, distance};
    });

    // Sort the distances in ascending order
    return distances;
}

export const getKFCLocations = ({kfcs, latitude, longitude}) => {

    // roughly corresponds to 1/2 mile, 2.5 miles, 7 miles, 14 miles, 35 miles, 70 miles, 350 miles
    // ... if there isn't a KFC within 350 miles .. you're probably not going to order kfc.
    const latlngDeltas = [0.01, 0.05, 0.1, 0.2, 0.5, 1, 5];
    let nearestKFCs = [];

    latlngDeltas.some(latlngDelta => {
        const halfDelta = latlngDelta / 2;
        const locationsQueryResult = geospatialIndex.range(
            latitude - halfDelta,  // southWest.latitude,
            longitude - halfDelta, // southWest.longitude,
            latitude + halfDelta,  // northEast.latitude,
            longitude + halfDelta  // northEast.longitude
        );
        const hasLocations = locationsQueryResult.length > 1;
        if (hasLocations) {
            nearestKFCs = getDistances(
                locationsQueryResult.map(id => kfcs[id]),
                latitude,
                longitude
            )
            .sort((a, b) => a.distance - b.distance)
            .map(o => o.location);
        }
        return hasLocations;
    });

    return nearestKFCs;
};

export const getKFCLocationsByBounds = ({kfcs, latitude, longitude, distanceObject, latlngbounds}) => {
    // console.log({latitude, longitude, now: Date.now()});

    if (!latlngbounds) return;

    const southWest = latlngbounds.getSouthWest();
    const northEast = latlngbounds.getNorthEast();

    const locationsQueryResult = geospatialIndex.range(
        southWest.lat(),
        southWest.lng(),
        northEast.lat(),
        northEast.lng()
    );

    const nearestKFCs = getDistances(
        locationsQueryResult.map(id => kfcs[id]),
        distanceObject.latitude,
        distanceObject.longitude
    )
    .sort((a, b) => a.distance - b.distance)
    .map(o => o.location);

    return nearestKFCs;

};
