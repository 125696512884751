import KFC from './containers/KFC';
import './App.scss';

window.noop = () => {};

function App() {
  return (
    <div className="kfc">
      <KFC />
    </div>
  );
}

export default App;
